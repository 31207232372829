<template>
	<div>
		<div class="spinner-grow spinner" v-if="loading">
			<span class="sr-only"></span>
		</div>
		<!-- <navbar /> -->
		<div class="container" v-if="!loading">
			<div class="img-container">
				<div class="img"></div>
			</div>
			<div class="row">
				<div class="col" v-if="error"
					style="background: #F44336; display: flex; justify-content:center; margin: 10px 0; padding: 10px 0; border-radius: 6px">
					<div class="text-white">{{ error }}</div>
				</div>
			</div>
			<table class="table rentalTable">
				<thead class="table_head">
					<tr scope="col" style="text-align:center; background: #FFD54F; border-radius: 6px;">
						<th scope="col">#</th>
						<th scope="col">Rentals</th>
						<th scope="col">Tour Date</th>
						<th scope="col">Adult</th>
						<th scope="col">Child</th>
						<th scope="col">Infant</th>
						<th scope="col">Duration</th>
						<th scope="col">Limit</th>
						<th scope="col">Total</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="rental in rentals" :key="rental.RentalId">
						<td>
							<b-form-checkbox id="isSelected" v-model="SelectedRental.id" @change="checkme(rental)"
								:value="rental.RentalId" class="checkme" size="md"></b-form-checkbox>
						</td>
						<td class="subname" style="text-align: left">
							{{ rental.RenSub }}
						</td>
						<td>
							<b-form-datepicker id="rentalDate" type="text" class="form-control date" locale="en-US"
								:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
								v-model="SelectedRental.date" v-if="SelectedRental.id == rental.RentalId" placeholder=""
								reset-button></b-form-datepicker>
						</td>
						<td>
							<b-form-select v-if="SelectedRental.id == rental.RentalId && SelectedRental.date"
								:options="options.passengers" v-model="SelectedRental.adults">
								<option value="0"></option>
							</b-form-select>
						</td>
						<td>
							<b-form-select v-if="SelectedRental.id == rental.RentalId && SelectedRental.date"
								:options="options.passengers" v-model="SelectedRental.child">
								<option value="0"></option>
							</b-form-select>
						</td>
						<td>
							<b-form-select v-if="SelectedRental.id == rental.RentalId && SelectedRental.date"
								:options="options.passengers" v-model="SelectedRental.infant">
								<option value="0"></option>
							</b-form-select>
						</td>
						<td style="text-align:center">
							<b-form-select v-if="SelectedRental.id == rental.RentalId && SelectedRental.date"
								:options="options.limits" v-model="SelectedRental.duration">
								<option value="1"></option>
							</b-form-select>
						</td>
						<td style="text-align:center">
							{{ rental.RentalLimit }} people
						</td>
						<td>
							<p v-if="SelectedRental.id == rental.RentalId" v-show="setPrice(rental)" class="price">AED
								{{ SelectedRental.price }}
							</p>
							<p
								v-else-if="!SelectedRental.id || (SelectedRental.id && !SelectedRental.infant && !SelectedRental.adults && !SelectedRental.child)">
								AED {{ rental.AdultAED }}</p>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="row" style="position: sticky; bottom: 0">
				<div class="col" style="display:flex; justify-content:flex-end;">
					<button class="addcart" @click="addtocart()">Add To Cart</button>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
// import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";

export default {
	name: "Rental",
	data() {
		return {
			loading: false,
			rentals: {},
			name: "",
			SelectedRental: {},
			options: {
				passengers: [],
				limits: []
			},
			rental: {},
			error: ""
		}
	},
	mounted() {
		this.name = window.location.pathname.split("/rental/", -1)[1]
		this.$store.dispatch('rentals/getRentalsByName', this.name).then((response) => {
			if (response.status === 200) {
				this.loading = false
				this.rentals = response.data
			} else {
				this.$router.go()
			}

		})
	},
	components: {
		// Navbar,
		Footer
	},
	methods: {
		checkme(object) {
			this.rental = object
			for (let i = 0; i <= 30; i++) {
				this.options['passengers'].push(i)
			}
			for (let i = 1; i <= 8; i++) {
				this.options['limits'].push(i)
			}
			return this.SelectedRental.name = object.RenSub
		},
		setPrice(object) {
			const adult = this.SelectedRental.adults || 0
			const child = this.SelectedRental.child || 0
			const infant = this.SelectedRental.infant || 0
			const limit = this.SelectedRental.duration || 1

			return this.SelectedRental.total = ((adult * object.AdultAED) + (child * object.Child) + (infant * object.Infant)) * limit
		},
		async addtocart() {
			if (!this.SelectedRental.id) return this.error = "Please select a rental"
			// require one adult
			if (!this.SelectedRental.adults || this.SelectedRental.adults < 1) {
				return this.error = "Atleast 1 Adult is required"
			}
			// require duration
			if (!this.SelectedRental.duration) {
				return this.error = "Please select the duration of your rental!"
			}
			// limits
			const total_passengers = parseFloat(this.SelectedRental.adults) + parseFloat(this.SelectedRental.child) + parseFloat(this.SelectedRental.infant)
			if (total_passengers > parseFloat(this.rental.RentalLimit)) {
				return this.error = `Maximum Passengers exceeded. [ ${this.rental.RentalLimit} people ]`
			}

			var size = Object.keys(this.SelectedRental).length
			if (this.SelectedRental.id && size >= 7) {
				await this.$store.dispatch('cart/saveCart', this.SelectedRental)
				this.error = "Package Added Successfully!"
				setTimeout(() => {
					return this.$router.push('/packages')
				}, 1200)
			} else {
				return this.error = "Please select all the required options!"
			}


		}

	}
}
</script>


<style lang="scss" scoped>
.img-container
{
	display: flex;
	width: 100%;
	justify-content: center;
	margin: 3% 0;

	.img
	{
		width: 500px;
		height: 300px;
		background: #212121;
		border-radius: 6px;
		-webkit-box-shadow: 0px 3px 9px 0px rgb(231, 231, 231);
		-moz-box-shadow: 0px 3px 9px 0px rgb(236, 236, 236);
		box-shadow: 0px 3px 9px 0px rgb(238, 238, 238);
	}
}


td
{
	text-align: center;
}

.custom-select
{
	padding: 8.1%;
	border: .5px solid #616161;
	text-align: center;

}

.addcart
{
	background: #F44336;
	z-index: 3;
	padding: .5em 1.3em;
	outline: none;
	border: none;
	border-radius: 4px;
	color: white;
	transition: all 500ms ease-in-out;
	margin-bottom: 20px;
}

.addcart:hover
{
	background: #212121;
	border-radius: 0px;
	padding: .5em 1.4em;
	transition: all 500ms ease-in-out;
}
</style>
