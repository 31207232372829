<template>
  <div>
<div class="row">
  
    <div class="col col1">
    <b>UAE ACTIVITES</b> <br><br>
    DESERT SAFARI DUBAI <br>
    DUBAI CITY TOURS <br>
    DHOW CRUISE DUBAI <br>
    RAS AL KHAIMAH DESERT SAFARI <br>
    DUBAI WATER PARKS <br>
    BURJ KHALIFA TOURS <br>
    ABU DHABI CITY TOURS <br>
    DUBAI THEME PARK <br>
    DUBAI WATER ACTIVITES <br>
    DUBAI COMBO MEALS <br>
    ABU DHABI WATER PARKS <br>
    DUBAI ADVENTURE TOURS <br>
    ABUDHABI THEME PARKS <br>
    DUBAI HELICOPTER TOURS <br>
    DUBAI LIMOUSINE TOURS <br>
    

    </div>
     <div class="col ">
        <b>SERVICES</b> <br><br>
        DUBAI TOURS <br> 
        SINGAPORE TOURS <br>
        DUBAI VISA <br>
        DUBAI VISA EXTENSION <br>
        KUALA LAMPUR TOURS <br>
        DUBAI PACKAGES <br>
        CANADA VISA <br>
        UPCOMING EVENTS <br>
        SINGAPORE PACKAGES <br>
        AMSTERDAM TOURS <br>
        PRAGUE TOURS <br>
        GEORGIA PACKAGES <br>
        USA VISA <br>
        TRAVEL GUIDE + <br>
        INTERNATIONAL VISA + <br>
        HOLIDAY PACKAGES + <br>

    
    </div>
     <div class="col">
         <b>TOP 10</b> <br><br>
        DUBAI BURJ KHALIFA <br> 
        4*4 DESERT SAFARI <br>
        IMG WORLDS OF ADVENTURE <br>
        UMM AL QUWAIN ZOO <br>
        DUBAI EXPO 2020 <br>
        1 HOUR LIMOSUINE TOUR <br>
        2 HOUR LIMOSUINE TOUR <br>
        3 HOUR LIMOSUINE TOUR <br>
        4 HOUR LIMOSUINE TOUR <br>
        BIG BUS 15 HOUR DAY TOUR <br>
    </div>
     <div class="col">
        <b>UAE VISA</b> <br><br>
        96 HOUR TRANSIT VISA <br> 
        14 DAYS DUBAI VISA <br>
        30 DAYS DUBAI VISA <br>
        90 DAYS DUBAI VISA <br>
        30 DAYS MULTIPLE ENTRY DUBAI VISA <br>
        90 DAYS MULTIPLE ENTRY DUBAI VISA <br>
    </div>
     <div class="col">
         <b>INTERNATIONAL VISA</b> <br><br>
        UK VISA <br> 
        SCHENGEN VISA <br>
        SINGAPORE VISA <br>
        THAILAND VISA <br>
        MALAYSIA VISA <br>
        SRI LANKA VISA <br>
        TURKEY VISA <br>
        MALDIVES VISA <br>
        MORTH AMERICA VISA <br>
    </div>
   
    
     <div class="row">
        
   
    <div class="col col1">ABOUT US</div>
    <div class="col">CONTACT US</div>
    <div class="col link" @click="$router.push('/terms')">TERMS AND CONDITIONS</div>
    <div class="col link">PRIVACY POLICY</div>
        </div>
         <p class="text-center col">© 2021 | DESERT LINK TOURISM L.L.C</p>
    
    </div>
    
   
     </div>
</template>



<script>

export default{
    name: "Footer"
}
</script>

<style scoped>

.row{
    background: #D6C2AF;
}

.col1{
    margin-left: 5%;
}

.col{
    margin-top: 5%;
}

.link {
    text-decoration: blue;
}

</style>
